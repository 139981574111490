<template>
  <v-container class="general">
    <PageTitle :title="$t('group_menu_list.page_title')" />
    <transition name="fade" mode="out-in">
      <v-layout v-if="!isLoading && !menus.length" class="row wrap text-center">
        <v-flex class="xs12 sw-h5 font-weight-light">
          {{ $t("group_menu_list.empty_list") }}
        </v-flex>
      </v-layout>
    </transition>
    <template v-if="menus && menus.length > 0">
      <div v-for="(menu, i) in menus" :key="`menu-${i}`">
        <v-layout class="row no-wrap align-center">
          <v-flex class="xs12 py-3">
            <a
              class="sw-h5 font-weight-light"
              @click.stop="editMenu(menu.id)"
              >{{ menu.name }}</a
            >
            <div class="sw-caption grey--text">
              {{ getMenuName(menu.slug) }}
            </div>
          </v-flex>
          <v-btn
            icon
            class="ma-0"
            @click="
              $router.push({
                name: 'group_menu_edit',
                params: { group_id: groupId, menu_id: menu.id },
              })
            "
            :loading="isLoading"
          >
            <font-awesome-icon class="sw-accent" icon="pen" />
          </v-btn>
          <v-btn
            icon
            class="ma-0"
            @click="deleteGroupMenu(menu.id)"
            :loading="isLoading"
          >
            <font-awesome-icon class="sw-accent" icon="trash" />
          </v-btn>
        </v-layout>
        <v-divider></v-divider>
      </div>
    </template>
    <AppSpeedDial
      @click="
        $router.push({
          name: 'group_menu_create',
          params: { group_id: groupId },
        })
      "
      :hint="$t('group_menu_list.speed_dial_hint')"
    />
    <ConfirmationDialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";

export default {
  data: () => ({
    isLoading: false,
    menus: [],
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
  },
  components: {
    LanguageSwitcher,
  },
  watch: {
    appContentLanguage: {
      handler() {
        this.listGroupMenus();
      },
    },
  },
  mounted() {
    this.listGroupMenus();
  },
  methods: {
    getMenuName(slug) {
      if (!slug) return;

      if (slug === "group_menu" || slug === "group-menu") {
        return this.$t("group_menu.group_menu_label");
      }

      if (slug === "ionic-app-sidemenu") {
        return this.$t("group_menu.app_sidemenu_label");
      }
    },
    editMenu(menuId) {
      if (!menuId) return;

      this.$router
        .push({
          name: "group_menu_destination_list",
          params: {
            group_id: this.groupId,
            menu_id: menuId,
          },
        })
        .catch(() => {});
    },
    async listGroupMenus() {
      try {
        this.isLoading = true;

        const params = [
          this.groupId,
          {
            lang: this.appContentLanguage,
          },
        ];

        const response = await this.$api.groupMenus.list(...params);

        this.menus = response.data.data;

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async deleteGroupMenu(id) {
      if (!id) return;

      const isConfirmed = await this.$refs.confirmationDialog.open(
        this.$t("group_menu_list.delete_warning"),
        this.$t("common.no"),
        this.$t("common.yes"),
      );

      if (!isConfirmed) return;

      try {
        const params = [this.groupId, id];

        this.isLoading = true;

        await this.$api.groupMenus.delete(...params);

        this.$store.dispatch("addNotification", {
          message: this.$t("group_menu_list.delete_success_message"),
        });

        this.listGroupMenus();
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
  },
};
</script>
