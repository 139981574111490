var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"general"},[_c('PageTitle',{attrs:{"title":_vm.$t('group_menu_list.page_title')}}),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.isLoading && !_vm.menus.length)?_c('v-layout',{staticClass:"row wrap text-center"},[_c('v-flex',{staticClass:"xs12 sw-h5 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t("group_menu_list.empty_list"))+" ")])],1):_vm._e()],1),(_vm.menus && _vm.menus.length > 0)?_vm._l((_vm.menus),function(menu,i){return _c('div',{key:`menu-${i}`},[_c('v-layout',{staticClass:"row no-wrap align-center"},[_c('v-flex',{staticClass:"xs12 py-3"},[_c('a',{staticClass:"sw-h5 font-weight-light",on:{"click":function($event){$event.stopPropagation();return _vm.editMenu(menu.id)}}},[_vm._v(_vm._s(menu.name))]),_c('div',{staticClass:"sw-caption grey--text"},[_vm._v(" "+_vm._s(_vm.getMenuName(menu.slug))+" ")])]),_c('v-btn',{staticClass:"ma-0",attrs:{"icon":"","loading":_vm.isLoading},on:{"click":function($event){return _vm.$router.push({
              name: 'group_menu_edit',
              params: { group_id: _vm.groupId, menu_id: menu.id },
            })}}},[_c('font-awesome-icon',{staticClass:"sw-accent",attrs:{"icon":"pen"}})],1),_c('v-btn',{staticClass:"ma-0",attrs:{"icon":"","loading":_vm.isLoading},on:{"click":function($event){return _vm.deleteGroupMenu(menu.id)}}},[_c('font-awesome-icon',{staticClass:"sw-accent",attrs:{"icon":"trash"}})],1)],1),_c('v-divider')],1)}):_vm._e(),_c('AppSpeedDial',{attrs:{"hint":_vm.$t('group_menu_list.speed_dial_hint')},on:{"click":function($event){return _vm.$router.push({
        name: 'group_menu_create',
        params: { group_id: _vm.groupId },
      })}}}),_c('ConfirmationDialog',{ref:"confirmationDialog"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }